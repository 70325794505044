import { useEffect } from 'react';
import { useNavigate, redirect } from 'react-router-dom'; // Using react-router-dom v6
import { useAuth0 } from "@auth0/auth0-react";

const Auth: React.FC = () => {
    const { isAuthenticated, user } = useAuth0();
    const navigate = useNavigate();

    const sendUsernameToServer = (username: string) => {
        // Create the JSON body to send to the server
        const requestBody = JSON.stringify({ username });

        // Replace 'your-server-endpoint' with the actual server endpoint URL
        //fetch('http://192.168.0.179:8000/company_auth', {
        fetch('https://tp99tr2tut.us-east-1.awsapprunner.com/company_auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody,
        })
        .then(response => {
            if(response.ok) {// Handle the response from the server if needed
            console.log('Username sent to the server successfully!');
            // Redirect to the homepage after sending the username
            navigate('/'); // Replace '/homepage' with your actual homepage route
            }
            else {
                console.error('Failed to Username.');
            }
        })
        .catch(error => {
            // Handle any errors that occurred during the request
            console.error('Error sending username to the server:', error);
            // Redirect to the homepage even if there was an error
            //navigate('/'); // Replace '/homepage' with your actual homepage route
        });
    };

    useEffect(() => {
        if (isAuthenticated && user?.name) {
            // Extract the username from the user object
            const { name } = user;
            // Send the username to the server and redirect to the homepage
            sendUsernameToServer(name);
        }
    }, [isAuthenticated, user, navigate]);

    return null; // Render nothing or a loading spinner while redirecting
};

export default Auth;
