import React, { useEffect } from 'react';
import logo from '../image/Logo(Transparent).png';

function Footer() {
	return (
		<div className='footer'>
			<footer>
				<div className="footer-contact">
					<h3>Give us a call</h3>
					<ul>
						<li><a href="tel:123-456-7890" className="footer-link">(+1) 403 619 0808</a></li>
					</ul>

					<div></div>

					<h3>Visit our office</h3>
					<ul>
						<li><a href="https://www.google.com/maps/place/2719+7+Ave+NE+%23211,+Calgary,+AB+T2A+2L9/data=!4m2!3m1!1s0x53717ad538eaaaab:0x64e110cef4571fc1?sa=X&ved=2ahUKEwiiwPzIpOL4AhVGBzQIHRtZAOUQ8gF6BAgSEAE"
							className="footer-link">Unit 211, 2719 7th Ave NE,<br /> Calgary, Alberta, Canada</a></li>
					</ul>
				</div>
				<div className="footer-references">
					<h3>References</h3>
					<ul>
						<li><a href="priceEN.html" className="footer-link ">Prices</a></li>
						<li><a href="contact.html" className="footer-link ">Contact</a></li>
						<li><a href="about.html" className="footer-link ">About</a></li>
					</ul>
				</div>
				<div className="footer-about">
					<h3>Guan's Accounting</h3>
					<p>
						Placeholder About Me
					</p>

				</div>
				<div className="footer-socialmedia">
					<a href="https://www.facebook.com/daniel8guan/about"><i className="fa fa-facebook" aria-hidden="true"></i></a>
					<a href="https://ca.linkedin.com/in/daniel-guan-24a8753b?trk=people-guest_people_search-card"><i
						className="fa fa-linkedin" aria-hidden="true"></i></a>
				</div>
			</footer>

			<img src={logo} className="footer-img" />
			<div className="footer-copyright">
				<p>Guan's Investment Group © 2023</p>
			</div>
		</div>
	);
}

export default Footer;