
function Homepage() {
	return (
		<>
			<section className="layout" id="home">
				<div className="text-box">
					<div className="section">
						<h1>Easy Taxes</h1>
						<p>Simply call and we'll get the job done</p>
						<a href="priceEN.html" className="adPrice-btn">Check our prices</a>
					</div>

					<div className="section">
						<h1>Oil & Tire Change</h1>
						<p>Oil & Tire Change Service</p>
						<a href="service.html" className="adPrice-btn">See more details</a>
					</div>
				</div>
			</section>
		</>
	);
}

export default Homepage;
