import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Define types for the user object
interface User {
    name: string;
    email: string;
    picture: string;
}

const Profile: React.FC = () => {
    // Add type for the returned object from useAuth0()
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>
    }

    // Make sure to check if user exists before accessing its properties
    return isAuthenticated && user ? (
        <div className="profile-text">
            <p>logged in as {user.name}</p>
        </div>
    ) : null;
};

export default Profile;
