function About() {
  return (
    <>
      <section className="layout" id="about">
		    <div className="text-box">
			    <h1>Easy Taxes</h1>
			    <p>Placeholder About Me Text</p>
		    </div>
	    </section>  
    </>
  );
};

export default About;