import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import DropdownMenu from './components/DropdownMenu';


interface DaySchedule {
    day: string;
    hours: number;
}

const WeekScheduler: React.FC = () => {
    //const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([]);
    //const [newEntry, setNewEntry] = useState<TimeEntry>({ task: '', hours: 0 });
    const [startDate, setStartDate] = useState(getStartOfWeek());

    const [selectedOption, setSelectedOption] = useState<string>("");
    const handleDropdownChange = (selectedValue: string) => {
        setSelectedOption(selectedValue);
    };


    const [weekSchedule, setWeekSchedule] = useState<DaySchedule[]>(() => {
        const startDate = getStartOfWeek();
        const days = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);
            const formattedDate = currentDate.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric'
            });
            days.push({ day: formattedDate, hours: 0 });
        }
        return days;
    });

    const [submitting, setSubmitting] = useState(false); // New state variable

    // Grab the start of the week
    function getStartOfWeek() {
        const date = new Date();
        const day = date.getDay();
        const diff = (day === 0 ? 6 : day - 1); // Adjust for weeks starting on Monday
        date.setDate(date.getDate() - diff);
        return date;
    }

    // Submit to server
    const handleSubmit = () => {
        setSubmitting(true);

        const selectedWeekDays = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() + i);
            selectedWeekDays.push({
                date: currentDate.toISOString().slice(0, 10),
                hours: weekSchedule[i].hours, // Use the hours from the state
            });
        }

        // Create the payload object containing the hours data
        const payload = {
            name: selectedOption,
            weekSchedule: selectedWeekDays,
        };

        console.log(payload);
        // Send the POST request to the server
        fetch('https://tp99tr2tut.us-east-1.awsapprunner.com/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    // Handle successful response
                    console.log('Hours submitted successfully!');
                } else {
                    // Handle error response
                    console.error('Failed to submit hours.');
                }
            })
            .catch(error => {
                console.error('An error occurred while submitting hours:', error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };


    const handleDateChange = (weeks: number) => {
        const newDate = new Date(startDate);
        newDate.setDate(newDate.getDate() + weeks * 7);
        setStartDate(newDate);
    };

    const renderDateLabel = (days: number) => {
        const currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() + days);
        //const options = { weekday: 'short', year: 'numeric', month: 'short', year: 'numeric' };
        return currentDate.toLocaleDateString('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const handleHoursChange = (day: string, hours: number) => {
        setWeekSchedule(prevState => {
            const updatedSchedule = prevState.map(schedule => {
                if (schedule.day === day) {
                    return { ...schedule, hours };
                }
                return schedule;
            });
            return updatedSchedule;
        });
    };

    const totalHours: number = weekSchedule.reduce((total, schedule) => total + schedule.hours, 0);
    const { isAuthenticated } = useAuth0();
    return (isAuthenticated ? (

        <section className="layout" id="home">

            <div className="WeekScheduler, text-box">
                <div className="Timesheet-header">
                    <DropdownMenu onChange={handleDropdownChange} />

                    <div className='date-selector'>
                        <button className='btn-arrow' onClick={() => handleDateChange(-1)}><i className='arrow left'></i></button>
                        <h2 className='date-text'>
                            {renderDateLabel(0)} - {renderDateLabel(6)}
                        </h2>
                        <button className='btn-arrow' onClick={() => handleDateChange(1)}><i className='arrow right'></i></button>
                    </div>



                </div>



                <table>
                    <thead>
                        <tr>
                            {Array.from({ length: 7 }).map((_, index) => (
                                <th key={index}>{renderDateLabel(index)}</th>

                            ))}
                            <th>Total Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {weekSchedule.map(schedule => (
                                <td key={schedule.day}>
                                    <input
                                        type="number"
                                        value={schedule.hours}
                                        onChange={e => handleHoursChange(schedule.day, Number(e.target.value))}
                                    />
                                </td>
                            ))}
                            <td>{totalHours}</td>
                        </tr>
                    </tbody>
                </table>

                <div>
                    <button onClick={handleSubmit} disabled={submitting}>
                        {submitting ? 'Submitting...' : 'Submit'}
                    </button>
                </div>

            </div>
        </section>
    ) : <></>
    );
};

export default WeekScheduler;