function PageNotFound() {
return(
    <>
    <section className="layout" id="home">
       <div className='text-box'>
            <h1>404 NOT FOUND</h1>
        </div>
    </section>
    </>
    )
}

export default PageNotFound;