function Prices() {
return(
    <>
	<section className="layout" id="home">

        <div className='text-box'>
        <div className="home_content">
				<div className="priceContent" >
					<h2 className="table_title" >For 2023 Tax Year</h2>
					<a href="priceEN.html" className="button">English</a>
					<a href="priceCHN.html" className="button">中文</a>
					<table>
						<tr>
							<th>Services</th>
							<th>Charges</th>
						</tr>
						<tr>
							<td>
								<ul>
									<li>Personal Tax (T1)</li>
									<li>(Including all T Slips)</li>
								</ul>
							</td>
							<td>$40/Per Return<br />+$10 for Home Visit</td>
						</tr>
						<tr>
							<td>Rental Properties </td>
							<td>+$20/Per Property</td>
						</tr>
						<tr>
							<td>
								Stocks, Shares, Investments
							</td>
							<td>+$20</td>
						</tr>
						<tr>
							<td>Self-Employed</td>
							<td>+$50</td>
						</tr>
						<tr>
							<td>Employment Expense Reporting</td>
							<td>+$20</td>
						</tr>
						<tr>
							<td>Foreign Assets Reporting</td>
							<td>+$50</td>
						</tr>
						<tr>
							<td>Moving Expenses Reporting</td>
							<td>+$20</td>
						</tr>
						<tr>
							<td>Property Sale Reporting</td>
							<td>+$20</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</section>
    </>
    )
}

export default Prices;