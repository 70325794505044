import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();

    const buttonStyle = {
        width: '9vh',
        height: '3vh',
        border: '0px',
        cursor: 'pointer',
        alignItems: 'center'
    }

    return (!isAuthenticated ?
        (<button onClick={() => loginWithRedirect()} style={buttonStyle}>
            Log In
        </button>) : <></>);
};

export default LoginButton;