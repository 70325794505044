function Contacts() {
	return (
		<>
		<section className="layout" id="home">
			<div className="contact-information">
				<h1>Our Information:</h1>
				<p>Phone: (+1) 403 619 0808</p>
				<p>Email: guansinvestmentgroup@hotmail.com</p>
				<p>Address: Unit 211, 2719 7th Ave NE, Calgary, Alberta, Canada, T2A 2L9</p>
			</div>

			<div className="contact-location">
				<h1>Our Location</h1>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.7317239635777!2d-113.99745758408743!3d51.05804125127901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717ad538eaaaab%3A0x64e110cef4571fc1!2s2719%207%20Ave%20NE%20%23211%2C%20Calgary%2C%20AB%20T2A%202L9!5e0!3m2!1sen!2sca!4v1656537141607!5m2!1sen!2sca"
					width="600" height="450" loading="lazy"></iframe>
			</div>
		</section>
			

		</>
	);
}

export default Contacts;