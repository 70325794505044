import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
    const { logout, isAuthenticated } = useAuth0();

    const buttonStyle = {
        width: '9vh',
        height: '3vh',
        border: '0px',
        cursor: 'pointer',
        alignItems: 'center'
    }

    return (isAuthenticated ? (
        <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} style={buttonStyle}>
            Log Out
        </button>) : <></>
    );
};

export default LogoutButton;