import { BrowserRouter, Routes, Route, Navigate, redirect } from 'react-router-dom';
import About from './pages/About';
import Homepage from './pages/Homepage';
import Prices from './pages/Prices';
import PageNotFound from './pages/PageNotFound';
import Contacts from './pages/Contacts';
import WeekScheduler from './pages/WeekScheduler';
import Footer from './pages/components/Footer'
import Header from './pages/components/Header';
import Auth from './pages/Auth';

function App() {
    return (
        <>
            <Header />
            <div id="page-container">
                <div id='content-wrap'>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path="/"
                                element={<Homepage />}
                            />
                            <Route path="/contacts"
                                element={<Contacts />}
                            />
                            <Route path="/auth"
                                element={<Auth />}
                            />
                            <Route path="/price"
                                element={<Prices />}
                            />
                            <Route path="*"
                                element={<PageNotFound />}
                            />
                            <Route path="/timesheet"
                                element={<WeekScheduler />}
                            />
                            <Route path="/about"
                                element={ <About /> }
                            />
                        </Routes>
                    </BrowserRouter>
                </div>
                <Footer />
            </div>

        </>
    );
}
export default App;