import React, { useState } from 'react';
import logo from '../image/Logo(Transparent).png';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import Profile from './Profile';
import { useAuth0 } from '@auth0/auth0-react';

const navLinks = document.getElementById("navLinks")

function showMenu() {
	if (navLinks != null) {
		navLinks.style.right = "0";
	}
}

function hideMenu() {
	if (navLinks != null) {
		navLinks.style.right = "-200px";
	}
}



function Header() {

	return (
		<div>
			<head>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<link href="https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css" rel="stylesheet" />
				<title>Guan's Accounting</title>
				<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
			</head>

			<header>


				<p className="title">Guan's Accounting</p>
				<div className='head'>

					<a className='logo' href="/"> <img src={logo} alt="logo" /></a>


				</div>


				<div>

				</div>
				<nav>
					<div className="nav-links" id="navLinks">
						<i className="fa fa-times" onClick={() => { hideMenu() }}></i>
						<ul>
							<li className='clickable'><a href="/">Home</a></li>
							<li className='clickable'><a href="/price">Price</a></li>
							<li className='clickable'><a href="/about">About</a></li>
							<li className='clickable'><a href="/contacts">Contact</a></li>
							<li className='clickable'><a href="/timesheet">Time Sheet</a></li>
							<li className='clickable'><a href="/">Oil & Tire Change Service</a></li>
							<li className='nav-login-button'><LogoutButton /><LoginButton /></li>
						</ul>

					</div>
					<div className="nav-profile"><Profile /></div>
					<i className="fa fa-bars" onClick={() => { showMenu() }}></i>
				</nav>
			</header>
		</div>
	);
}

export default Header;