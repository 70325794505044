import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface DropdownMenuProps {
  onChange: (selectedOption: string) => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ onChange }) => {
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const { isAuthenticated, user } = useAuth0();
  const [employeeNames, setEmployeeNames] = useState<string[]>([]);
  const [companyName, setCompanyName] = useState<string>(""); // State for company name

  // Fetch the JSON data from the server
  useEffect(() => {
    if (isAuthenticated && user?.name) {
      const { name } = user;
      sendUsernameToServer(name);
    }
  }, [isAuthenticated, user]);

  const sendUsernameToServer = (username: string) => {
    const requestBody = JSON.stringify({ username });

    //fetch("http://192.168.0.179:8000/employee_names", {
    fetch("https://tp99tr2tut.us-east-1.awsapprunner.com/employee_names", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: requestBody,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Username sent to the server successfully!");
          return response.json(); // Assuming the response contains the employee names
        } else {
          console.error("Failed to send username.");
        }
      })
      .then((data) => {
        if (data && typeof data === "object") {
          const employeeNamesArray = Object.values(data.employees) as string[]; // Assuming the employee names are in 'employees' property
          setEmployeeNames(employeeNamesArray);
          setCompanyName(data.name); // Assuming the company name is in 'company' property
        }
      })
      .catch((error) => {
        console.error("Error sending username to the server:", error);
      });
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onChange(selectedValue);
  };

  return (
    <div>
      <select onChange={handleDropdownChange} value={selectedOption}>
        <option value="">Select an employee</option>
        {employeeNames.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <h4 className="employee-text">Selected option: {selectedOption}</h4>
    </div>
  );
};

export default DropdownMenu;
